import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { environment } from '../../../environments/environment';
import { USER_PROFILE } from './user-profile';

declare const PubNub: any;

@Injectable({
	providedIn: 'root'
})
export class PubnubService {

	public pubnub: any;
	userProfile = USER_PROFILE;

	constructor(
		public globals: Globals
		) { }

	init(): any {
		this.pubnub = new PubNub({
			publishKey: environment.pubnub.publishKey,
			subscribeKey: environment.pubnub.subscribeKey,
			userId: String(this.userProfile.user_id)
		});
		return this.pubnub;
	}

	changeState(channels, data): void {
		const state = {
			uuid: this.userProfile.user_id,
			full_name: this.userProfile.full_name,
			timestamp: Math.floor(Date.now() / 1000)
		};
		Object.assign(state, data);
		this.pubnub.setState({
			channels,
			uuid: this.userProfile.user_id,
			state
		});
	}

	async getPresenceHistory(c): Promise<any> {
		try {
			const result = await this.pubnub.hereNow({
				channels: c,
				includeState: true
			});
			const occupants = result?.channels[c]?.occupants;
			occupants?.forEach(oc => {
				const stat = String(oc?.state?.status)
				if (stat !== 'undefined' && typeof stat !== 'undefined' && typeof stat !== undefined) {
					if (stat == 'occupied') {
						this.globals.userPresenceNew.set(Number(oc?.state?.patientReviewed), oc?.state?.reviewer);
						this.globals.patientListUnderReviewNumber++;
					} else if (stat == 'vacant') {
						this.globals.userPresenceNew.delete(Number(oc?.state?.patientReviewed));
					}
				}
			});
		} catch (err) {
			console.log(err);
		}
	}

	async getReferralPresenceHistory(c): Promise<any> {
		try {
			const result = await this.pubnub.hereNow({
				channels: c,
				includeState: true
			});
			const occupants = result?.channels[c]?.occupants;
			occupants?.forEach(oc => {
				const stat = String(oc?.state?.status)
				if (stat !== 'undefined' && typeof stat !== 'undefined' && typeof stat !== undefined) {
					if (stat == 'occupied') {
						this.globals.referralPresence.set(Number(oc?.state?.referralReviewed), oc?.state?.referralReviewer);
						this.globals.referralListUnderReviewNumber++;
					} else if (stat == 'vacant') {
						this.globals.referralPresence.delete(Number(oc?.state?.referralReviewed));
					}
				}
			});
		} catch (err) {
			console.log(err);
		}
	}
}
