import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from "@angular/router";
import { isThursday } from 'date-fns';
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { Globals } from 'src/app/globals';
import { ListenerService } from 'src/app/shared/services/listener.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { PubnubService } from 'src/app/shared/services/pubnub.service';
import { USER_PROFILE } from 'src/app/shared/services/user-profile';
import { UtilityHelperService } from 'src/app/shared/services/utility-helper.service';
import { VideoCallService } from 'src/app/shared/services/video-call.service';
import { IBreadcrumb } from "../../shared/interfaces/breadcrumb.type";
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { TaskService } from 'src/app/shared/services/task.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ChatMessageService } from 'src/app/shared/services/chat-message.service';
import { VoiceService } from 'src/app/shared/services/voice.service';


@Component({
    selector: 'app-common-layout',
    templateUrl: './common-layout.component.html',
    styleUrls: ['./common-layout.css']
})

export class CommonLayoutComponent implements OnInit {

    breadcrumbs$: Observable<IBreadcrumb[]>;
    contentHeaderDisplay: string;
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;
    selectedHeaderColor: string;

    userProfile = USER_PROFILE;
    videoIdentity = this.userProfile.user_id + this.userProfile.full_name.replace(/\s*/g, '');
    videoCallChannel = this.globals.videoCallChannel;
    pubnub;

    showFloatingCall = {
        'status': false,
        'patientName': null,
        'dob': null,
        'physician': null
    };


    biometricsSubscription: Subscription;

    isVoiceMuted = false;

    showRibbon = false;
    patientUserId: any;
    
    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private themeService: ThemeConstantService,
        private pubnubService: PubnubService,
        private videoCall: VideoCallService,
        public globals: Globals,
        private utilityHelper: UtilityHelperService,
        private listenerService: ListenerService,
        private patientService: PatientService,
        private taskService: TaskService,
        private message: NzMessageService,
        private chatMessageService: ChatMessageService,
        private voiceService: VoiceService,
        private route:ActivatedRoute
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data && child.snapshot.data['headerDisplay']) {
                        return child.snapshot.data['headerDisplay'];
                    } else {
                        return null;
                    }
                }
                return null;
            })
        ).subscribe((data: any) => {
            this.contentHeaderDisplay = data;
        });
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams['s'] === 'nw') {
            const currentUrl = this.router.url;
            const regex = new RegExp(/\/patients\/[0-9]+\?s=nw/);
            // console.log(this.route.snapshot.queryParams);
            if (regex.test(currentUrl)) {
                console.log('here aspaspas');
                let patientUrl = currentUrl.split('?')[0];
                let index = patientUrl.lastIndexOf('/');
                patientUrl = patientUrl.substring(index + 1);
                this.showRibbon = true;
                this.patientUserId = patientUrl;
            }
        }

        this.router.events.subscribe(event => {
            if (event instanceof ActivationStart) {
                if (event.snapshot.data['routeName'] && event.snapshot.data['routeName'] === 'patient_chart') {
                    this.patientUserId = event.snapshot.params.id;
                    this.showRibbon = true;
                } else {
                    this.showRibbon = false;
                    this.patientUserId = null;
                }
            }
        });

        this.breadcrumbs$ = this.router.events.pipe(
            startWith(new NavigationEnd(0, '/', '/')),
            filter(event => event instanceof NavigationEnd), distinctUntilChanged(),
            map(data => this.buildBreadCrumb(this.activatedRoute.root))
        );
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
        this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.pubnub = this.pubnubService.init();
        this.subscribeToPubnub(this.videoCallChannel);
        this.getIncomingRequest('requests');
        this.getIncomingRequest('room');
        this.getIncomingRequest('accepted');
        this.getIncomingRequest('missed');
        this.biometricsListener();
        this.patientStatusListener();
        this.voiceCallStatusListener();
        this.stagingVoiceCallStatusListener();
        this.pubnubService.getPresenceHistory(['patient-list-status']);
        this.getAlerts();
        this.getAssignedTasks();
        this.assignedTaskListener();
        this.alertStatusListener();
        this.shippingStatusListener();
        this.readyFloatingCallButton();
        this.alertChatUpdate();
        this.getUnseenMessageList();
        this.watchSeenMessage();
        this.getCurrentMonthMinutes();
    }

    getCurrentMonthMinutes(){
        this.patientService.getCurrentMonthBillableMinutes().subscribe(
            (response) => {
                this.globals.billableMinutes = response.data.minutes;
                this.globals.todayBillableMinutes = response.data.today_minutes;
            },    
            (error) => {
                console.log(error);
            }
        );
    }

    private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
        let label = '', path = '/', display = null;

        if (route.routeConfig) {
            if (route.routeConfig.data) {
                label = route.routeConfig.data['title'];
                path += route.routeConfig.path;
            }
        } else {
            label = 'Dashboard';
            path += 'dashboard';
        }

        const nextUrl = path && path !== '/dashboard' ? `${url}${path}` : url;
        const breadcrumb = <IBreadcrumb>{
            label: label, url: nextUrl
        };

        const newBreadcrumbs = label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
        if (route.firstChild) {
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }

    subscribeToPubnub(c): void {
        this.pubnub.addListener({
            presence: (event) => {
                if (c == event.channel) {
                    if (event.action == 'leave' || event.action == 'timeout') {
                        // console.log('leave',event);
                    } else if (event.action === 'state-change' && event.state.receiver_id === this.userProfile.user_id) {
                        switch (event.state.call_status) {
                            case 'patient_requested':
                                this.getIncomingRequest('requests');
                                this.getIncomingRequest('accepted');
                                break;
                            case 'waiting_room': {
                                this.getIncomingRequest('room');
                                this.getIncomingRequest('requests');
                                this.getIncomingRequest('accepted');
                                break;
                            }
                            default: break;
                        }
                    }
                }
            }
        });
        this.pubnub.subscribe({
            channels: [c],
            withPresence: true
        });
    }

    getIncomingRequest(callStatus): void {
        const pageIndex: number = 1;
        const pageSize: number = 10;
        const sortField = 'created_at';
        const sortOrder = 'ASC';
        const filter = {};
        this.videoCall.list(pageIndex, pageSize, sortField, sortOrder, filter, callStatus).subscribe({
            next: (data) => {
                if (data.status === 'success') {
                    if (callStatus === 'requests') {
                        this.globals.requestCount = data.data.pagination.total;
                        this.globals.checkNewAlert('requests');
                        this.globals.videoCallLogs = data.data.video_call_logs;
                        this.globals.requestNotificationLists = [];
                        data.data.video_call_logs.forEach(el => {
                            const diff = this.utilityHelper.diffInMinutes(new Date(), new Date(el.created_at));
                            const notificationList = {
                                user_id: el.patient_user_id,
                                title: el.call_reason,
                                time: diff + ' min',
                                icon: 'video-camera',
                                color: 'ant-avatar-' + 'blue'
                            };
                            this.globals.requestNotificationLists.push(notificationList);
                        });
                    } else if (callStatus === 'room') {
                        this.globals.waitingRoomCount = data.data.pagination.total;
                        this.globals.checkNewAlert('waiting-room');
                        this.globals.waitingRoomLogs = data.data.video_call_logs;
                        this.globals.roomNotificationLists = [];
                        data.data.video_call_logs.forEach(el => {
                            const diff = this.utilityHelper.diffInMinutes(new Date(), new Date(el.updated_at));
                            const notificationList = {
                                user_id: el.patient_user_id,
                                title: el.call_reason,
                                time: diff + ' min',
                                icon: 'video-camera',
                                color: 'ant-avatar-' + 'red'
                            };
                            this.globals.roomNotificationLists.push(notificationList);
                        });
                    } else if (callStatus === 'accepted') {
                        this.globals.scheduledCount = data.data.pagination.total;
                        this.globals.scheduledLogs = data.data.video_call_logs;
                    }
                    else if (callStatus === 'missed') 
                    {
                        this.globals.missedCallLogs = data.data.video_call_logs;
                        this.globals.missedCallLogs.forEach(element => {
                        if(element.seen === 0)
                        {
                            this.globals.missedCallCount++;
                        }
                        });
                    }
                }
            },
            error: (error) => {
                console.log(error);
            },
            complete: () => {
            }
        });
    }
    biometricsListener(): void {
        this.biometricsSubscription = this.pubnub.addListener({
            message: (event) => {
                if (event.channel == 'Channel-rpm-biometrics') {
                    const userID = event.message.ids;
                    if (userID.find(x => x === this.userProfile.user_id)) {
                        let $data = {
                            readingType: 'reload',
                            users: userID
                        };
                        this.listenerService.fireReadingType($data);
                        if (event.message.isAlert) {
                            this.getAlerts();
                            this.listenerService.fireAlerts(userID); 
                            this.listenerService.updateAlertCount(userID);                      
                        }
                    }
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["Channel-rpm-biometrics"],
        });
    }

    patientStatusListener(): void {
        this.pubnub.addListener({
            presence: (event) => {
                if (event.channel == 'patient-list-status') {
                    if (event.action == 'leave' || event.action == 'timeout') {
                        this.globals.userPresenceNew.delete(Number(event?.state?.patientReviewed));
                    }
                    else {
                        const state = event?.state;
                        if (state?.status === 'occupied') {
                            this.globals.userPresenceNew.set(Number(state?.patientReviewed), state?.reviewer);
                            this.globals.patientListUnderReviewNumber++;
                        }
                        else if (state?.status === 'vacant') {
                            this.globals.userPresenceNew.delete(Number(state?.patientReviewed));
                            this.globals.patientListUnderReviewNumber--;
                        }
                    }
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["patient-list-status"],
            withPresence: true
        });
    }

    voiceCallStatusListener(): void {
        this.pubnub.addListener({
            message: (event) => {
                if (event.channel == 'Channel-rpm-voice-call-status') {
                    if (this.userProfile.user_id == event.message.userId) {
                        this.globals.voiceCallPatientId = event.message.patientId;
                        this.globals.voiceCallStatus = event.message.callStatus;
                        this.globals.voiceCallUserId = event.message.userId;
                        if(event.message.callStatus === 'completed')
                        {
                            this.listenerService.patientDetailsMuteCall({'status':false});
                            this.listenerService.showFloatingCall({'status': false});
                            this.message.create('success', `Call ended.`);
                        }
                    }
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["Channel-rpm-voice-call-status"],
        });
    }

    stagingVoiceCallStatusListener(): void {
        this.pubnub.addListener({
            message: (event) => {
                if (event.channel == 'Channel-rpm-staging-voice-call-status') {
                    if (this.userProfile.user_id == event.message.userId) {
                        this.globals.stagingVoiceCallPatientId = event.message.stagingPatientId;
                        this.globals.stagingVoiceCallStatus = event.message.callStatus;
                    }
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["Channel-rpm-staging-voice-call-status"],
        });
    }

    getAlerts(): void {
        const pageIndex: number = 1;
        const pageSize: number = 10;
        const sortField = 'created_at';
        const sortOrder = 'ASC';
        const filter = {};
        this.patientService.alertsList(pageIndex, pageSize, sortField, sortOrder, filter).subscribe({
            next: (data) => {
                this.globals.alertNotificationList = [];
                this.globals.alertLogs = data.data.patients;
                this.globals?.alertLogs?.forEach(el => {
                    const diff = this.utilityHelper.diffInMinutes(new Date(), new Date(el.updated_at));
                    const notificationList = {
                        user_id: el.id,
                        title: 'Vitals Alert',
                        time: el.full_name,
                        icon: 'warning',
                        color: 'ant-avatar-' + 'red'
                    };
                    this.globals.alertNotificationList.push(notificationList);
                });
                this.globals.alertCount = data.data.pagination?.total;
                this.globals.checkNewAlert('alerts')

            },
            error: (error) => {
                console.log(error);
            },
            complete: () => { }
        });
    }

    getAssignedTasks(): void {
        const pageIndex: number = 1;
        const pageSize: number = 10;
        const sortField = 'created_at';
        const sortOrder = 'ASC';
        this.taskService.list(pageIndex, pageSize, sortField, sortOrder, 'pending').subscribe({
            next: (data) => {
                this.globals.assignedTasksNotificationList = [];
                this.globals.assignedTasks = data.data.tasks;
                this.globals.assignedTasks.forEach(el => {
                    const taskNotificationList = {
                        task_id: el.id,
                        title: 'Assigned Tasks',
                        description: el.descrption,
                        icon: 'warning',
                        color: 'ant-avatar-' + 'red'
                    };
                    this.globals.assignedTasksNotificationList.push(taskNotificationList);
                });
                this.globals.assignedTaskCount = data.data.pagination.total;
                this.globals.checkNewAlert('tasks');

            },
            error: (error) => {
                console.log(error);
            },
            complete: () => { }
        });
    }




    alertStatusListener(): void {
        this.pubnub.addListener({
            message: (event) => {
                if (event.channel == 'vital-alerts-updated') {
                    if (event.message.ids.includes(this.userProfile.user_id)) {
                        this.getAlerts();
                        this.listenerService.fireAlerts(event.message.ids);
                    }
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["vital-alerts-updated"],
        });
    }

    shippingStatusListener(): void {
        this.pubnub.addListener({
            message: (event) => {
                if (event.channel == 'Channel-rpm-shipment-tracking') {
                    this.listenerService.fireTransactionId(event.message);
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["Channel-rpm-shipment-tracking"],
        });
    }

    

    assignedTaskListener(): void {
        this.pubnub.addListener({
            message: (event) => {
                if (event.channel == 'Channel-rpm-tasks') {
                    if (event.message.ids.includes(this.userProfile.user_id)) {
                        this.getAssignedTasks();
                    }
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["Channel-rpm-tasks"],
        });
    }

    readyFloatingCallButton(): void{
        this.listenerService.floatingCallObserve.subscribe({
            next: (data) => {
              this.showFloatingCall = data;
            }
          });
        this.listenerService.muteCallObserve.subscribe({
            next: (data) => {
              this.isVoiceMuted = data.status;
            }
          });
    }

    muteVoicecall() {
        if(this.isVoiceMuted) {
            this.voiceService.mute(false);
            this.isVoiceMuted = false;
            this.listenerService.patientDetailsMuteCall({'status':false});  
        } else {
            this.voiceService.mute(true);
            this.isVoiceMuted = true;
            this.listenerService.patientDetailsMuteCall({'status':true});
        }
    }
    
    disconnectVoice() {
        this.listenerService.showFloatingCall({'status':false});
        this.voiceService.disconnectCall();
    }

    alertChatUpdate(): void {
        this.pubnub.addListener({
            message: (event) => {
                if (event.channel == 'Channel-rpm-chat-alert') {
                    let userId = event.message.id;
                    this.listenerService.userPatientChatUpdate(userId);
                    this.listenerService.initiatePendingChat(userId);
                    this.getUnseenMessageList();
                }
            }
        });
        this.pubnub.subscribe({
            channels: ["Channel-rpm-chat-alert"],
        });
    }

    getUnseenMessageList(): void { 
        this.chatMessageService.getUnseenMessageList().subscribe({
            next: (data) => {         
                this.globals.unseenMessageList = [];       
                data.data.forEach(el => {
                    const unseenMessage = {
                        id: el.id, 
                        name: el.full_name,
                        title: 'Assigned Tasks',
                        description: el.full_name,
                        icon: 'warning',
                        color: 'ant-avatar-' + 'red',
                        messageCount: el.unseenMessageList,
                        practiceName: el.practice_name
                    };
                    this.globals.unseenMessageList.push(unseenMessage);
                });
                this.globals.unseenMessageCount = data.data.length;
            },
            error: (error) => {
                console.log(error);
            },
            complete: () => { }
        });
    }

    watchSeenMessage(): void{
        this.listenerService.messageSeen.subscribe({
            next: (data) => {
                this.getUnseenMessageList();
                //this.listenerService.seenMessage(false); 
            }
          });

    }
}