<div class="header" [ngClass]="{ headerWithRibbon: showRibbon }">
    <div class="logo logo-dark">
        <a href="">
            <img [src]="assets.logo" alt="Logo">
            <img class="logo-fold" [src]="assets.logo_fold" alt="Logo">
        </a>
    </div>
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div>

    <ng-template [ngIf]="showRibbon">
        <!--  width: isFolded ? 'calc(100% - 80px)' : 'calc(100% - 280px)' -->
        <div class="d-flex" [ngStyle]="{ flexDirection: 'column', flex: '0%'}"> 
            <div class="user-ribbon">
                <ng-template [ngIf]="patientLoading">
                    <ng-template #indicatorTemplate><span class="m-l-10 text-white" nz-icon nzType="loading"></span></ng-template>
                    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
                </ng-template>
                <ng-template [ngIf]="!patientLoading">
                    <span>{{ patient?.full_name }} ({{ patient?.gender ? patient?.gender : 'U' }})</span>
                    <span class="ribbon-divided">|</span>
                    <span>{{ patient?.date_of_birth }} ({{ this.utilityHelper.ageCalculator(patient?.date_of_birth) }})</span> 
                    <span class="ribbon-divided">|</span>
                    <span>H: {{ (patient?.home_phone_number | phone) || '--' }}</span>
                    <span class="ribbon-divided">|</span>
                    <span>M: {{ (patient?.phone_number | phone) || '--' }}</span>
                    <span class="ribbon-divided">|</span>
                    <span>{{ patient?.organization_name }}</span>
                    <ng-template [ngIf]="patient?.care_type.includes('RPM') ?? false">
                        <span class="ribbon-divided">|</span>
                        <span>RPM: {{ patient?.latest_clinical_minutes_rpm | minute }} Mins</span>
                    </ng-template>
                    <ng-template [ngIf]="patient?.care_type.includes('CCM') ?? false">
                        <span class="ribbon-divided">|</span>
                        <span>CCM: {{ patient?.latest_clinical_minutes_ccm | minute }} Mins</span>
                    </ng-template>
                </ng-template>
            </div>
            <div class="nav-wrap">
                <ul class="nav-left">
                    <li class="desktop-toggle">
                        <a (click)="toggleFold()">
                            <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                        </a>
                    </li>
                    <li class="mobile-toggle">
                        <a (click)="toggleExpand()">
                            <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                        </a>
                    </li>
                </ul>
                <ul class="nav-left"><li> Careteam </li></ul>
                <ul class="nav-right">
                    <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="unseenMessageNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.unseenMessageCount">
                                <i nz-icon nzType="mail" nzTheme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #unseenMessageNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="bell" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.unseenMessageList">
                                                <a (click)="goToPatient(item.id)">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{ item.name }}</p>
                                                            <p class="m-b-0 text-dark">{{ item.practiceName}}</p>
                                                            <p class="m-b-0"><small>{{item.messageCount}} new messages!</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- Alerts -->
                    <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="alertNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.alertCount">
                                <i nz-icon nzType="warning" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #alertNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="bell" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a (click)="setActiveBlock('alerts')" nz-button nzType="link" nzSize="small" class="p-v-5">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.alertNotificationList">
                                                <a (click)="setActiveBlock('alerts')">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}}</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- Class Request -->
                    <li>
                        <a nz-dropdown  nzTrigger="click" [nzDropdownMenu]="videoNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.requestCount">
                                <i nz-icon nzType="phone" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #videoNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="phone" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a (click)="setActiveBlock('requests')" nz-button nzType="link" nzSize="small" class="p-v-5">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.requestNotificationLists">
                                                <a (click)="setActiveBlock('requests')">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- Class Request -->
                    <li>
                        <a nz-dropdown  nzTrigger="click" [nzDropdownMenu]="roomNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.waitingRoomCount">
                                <i nz-icon nzType="video-camera-add" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #roomNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="video-camera-add" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a (click)="setActiveBlock('waiting-room')" nz-button nzType="link" nzSize="small" class="p-v-5">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.roomNotificationLists">
                                                <a (click)="setActiveBlock('waiting-room')">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="popNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="5">
                                <i nz-icon nzType="bell" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #popNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="bell" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a nz-button nzType="link" nzSize="small" class="p-v-5" [routerLink]="''">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzDataSource]="notificationList" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                                        <ng-template #item let-item>
                                            <nz-list-item>
                                                <a [routerLink]="''">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                        </ng-template>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li> -->

                    <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="appsList" [nzPlacement]="'bottomRight'">
                            <i nz-icon nzType="appstore" nzTheme="outline"></i>
                        </a>
                        <nz-dropdown-menu #appsList="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                    <!-- Current User Role -->
                                    <nz-list-item>
                                        <a href="javascript:void(0);">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="success"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Careteam</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Patient -->
                                    <nz-list-item *ngIf="hasRole('patient')">
                                        <a [href]="APP_ENDPOINTS.patient" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Patient</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Provider -->
                                    <nz-list-item *ngIf="hasRole('provider')">
                                        <a [href]="APP_ENDPOINTS.provider" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Provider</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Home Health  -->
                                    <nz-list-item *ngIf="hasRole('home_health')">
                                        <a [href]="APP_ENDPOINTS.home_health" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Home Health</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Admin -->
                                    <nz-list-item *ngIf="hasRole('admin')">
                                        <a [href]="APP_ENDPOINTS.admin" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Admin</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Super Admin -->
                                    <nz-list-item *ngIf="hasRole('super_admin')">
                                        <a [href]="APP_ENDPOINTS.super_admin" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Super Admin</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                </nz-list>
                            </div>
                        </nz-dropdown-menu>
                    </li>

                    <li>
                        <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                            <nz-avatar style="background-color:#FEB2B2;" nzIcon="user" *ngIf="userProfile.avatar === null; else showProfileImage"></nz-avatar>
                            <ng-template #showProfileImage>
                                <nz-avatar nzIcon="user" [nzSrc]="userProfile.avatar" [nzAlt]="'profile picture'"></nz-avatar>
                            </ng-template>
                        </span>
                        <nz-dropdown-menu #profile="nzDropdownMenu">
                            <ul nz-menu class="p-b-15 p-t-20">
                                <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                                    <div class="d-flex m-r-50">
                                        <nz-avatar nzSize="large" style="background-color:#FEB2B2;" nzIcon="user" *ngIf="userProfile.avatar === null; else showProfileImage"></nz-avatar>
                                        <ng-template #showProfileImage>
                                            <nz-avatar nzSize="large" nzIcon="user" [nzSrc]="userProfile.avatar"></nz-avatar>
                                        </ng-template>
                                        <div class="m-l-10">
                                            <p class="m-b-0 text-dark font-weight-semibold">{{ userProfile?.full_name }}</p>
                                            <p class="m-b-0 opacity-07">{{ userProfile?.role }}</p>
                                        </div>
                                    </div>
                                </li>
                                <li nz-menu-item>
                                    <a [routerLink]="['/account/profile']" class="p-v-5 d-flex align-items-center justify-content-between">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                            <span class="m-l-10">Edit Profile</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                                <li *ngIf="this.globals.bulkMessagePermission" nz-menu-item>
                                    <a [routerLink]="['/bulk-message']" class="p-v-5 d-flex align-items-center justify-content-between">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="send" theme="outline"></i>
                                            <span class="m-l-10">Send Message</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                                <li nz-menu-item>
                                    <a [routerLink]="['/account/setting']" class="p-v-5 d-flex align-items-center justify-content-between">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="setting" theme="outline"></i>
                                            <span class="m-l-10">Settings</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                                <li nz-menu-item>
                                    <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                            <span class="m-l-10">Logout</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                            </ul>
                        </nz-dropdown-menu >
                    </li>
                    <!-- <li>
                        <a (click)="quickViewToggle()">
                            <i nz-icon nzType="appstore" theme="outline"></i>
                        </a>
                        <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                            <app-quick-view></app-quick-view>
                        </nz-drawer>
                    </li> -->
                </ul>
            </div>
        </div>
    </ng-template>

    <ng-template [ngIf]="!showRibbon">
        <div class="nav-wrap">
                <ul class="nav-left">
                    <li class="desktop-toggle">
                        <a (click)="toggleFold()">
                            <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                        </a>
                    </li>
                    <li class="mobile-toggle">
                        <a (click)="toggleExpand()">
                            <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                        </a>
                    </li>
                </ul>
                <ul class="nav-left"><li> Careteam </li></ul>
                <ul class="nav-right">
                    <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="unseenMessageNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.unseenMessageCount">
                                <i nz-icon nzType="mail" nzTheme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #unseenMessageNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="bell" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.unseenMessageList">
                                                <a (click)="goToPatient(item.id)">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{ item.name}}</p>
                                                            <p class="m-b-0 text-dark">{{ item.practiceName}}</p>
                                                            <p class="m-b-0"><small>{{item.messageCount}} new messages!</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- Alerts -->
                    <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="alertNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.alertCount">
                                <i nz-icon nzType="warning" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #alertNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="bell" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a (click)="setActiveBlock('alerts')" nz-button nzType="link" nzSize="small" class="p-v-5">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.alertNotificationList">
                                                <a (click)="setActiveBlock('alerts')">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}}</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- Class Request -->
                    <li>
                        <a nz-dropdown  nzTrigger="click" [nzDropdownMenu]="videoNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.requestCount">
                                <i nz-icon nzType="phone" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #videoNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="phone" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a (click)="setActiveBlock('requests')" nz-button nzType="link" nzSize="small" class="p-v-5">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.requestNotificationLists">
                                                <a (click)="setActiveBlock('requests')">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- Class Request -->
                    <li>
                        <a nz-dropdown  nzTrigger="click" [nzDropdownMenu]="roomNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="globals.waitingRoomCount">
                                <i nz-icon nzType="video-camera-add" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #roomNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="video-camera-add" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a (click)="setActiveBlock('waiting-room')" nz-button nzType="link" nzSize="small" class="p-v-5">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                            <nz-list-item *ngFor="let item of globals.roomNotificationLists">
                                                <a (click)="setActiveBlock('waiting-room')">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li>
                    <!-- <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="popNotification" [nzPlacement]="'bottomRight'">
                            <nz-badge [nzCount]="5">
                                <i nz-icon nzType="bell" theme="outline"></i>
                            </nz-badge>
                        </a>
                        <nz-dropdown-menu #popNotification="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                                    <p class="text-dark font-weight-semibold m-b-0">
                                        <i nz-icon nzType="bell" theme="outline"></i>
                                        <span class="m-l-10">Notification</span>
                                    </p>
                                    <a nz-button nzType="link" nzSize="small" class="p-v-5" [routerLink]="''">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                                    <nz-list class="ant-list-item-links" [nzDataSource]="notificationList" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                                        <ng-template #item let-item>
                                            <nz-list-item>
                                                <a [routerLink]="''">
                                                    <div class="d-flex">
                                                        <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                        <div class="m-l-15">
                                                            <p class="m-b-0 text-dark">{{item.title}}</p>
                                                            <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </nz-list-item>
                                        </ng-template>
                                    </nz-list>
                                </perfect-scrollbar>
                            </div>
                        </nz-dropdown-menu>
                    </li> -->

                    <li>
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="appsList" [nzPlacement]="'bottomRight'">
                            <i nz-icon nzType="appstore" nzTheme="outline"></i>
                        </a>
                        <nz-dropdown-menu #appsList="nzDropdownMenu">
                            <div nz-menu class="pop-notification">
                                <nz-list class="ant-list-item-links" [nzItemLayout]="'horizontal'">
                                    <!-- Current User Role -->
                                    <nz-list-item>
                                        <a href="javascript:void(0);">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="success"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Careteam</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Patient -->
                                    <nz-list-item *ngIf="hasRole('patient')">
                                        <a [href]="APP_ENDPOINTS.patient" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Patient</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Provider -->
                                    <nz-list-item *ngIf="hasRole('provider')">
                                        <a [href]="APP_ENDPOINTS.provider" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Provider</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Home Health  -->
                                    <nz-list-item *ngIf="hasRole('home_health')">
                                        <a [href]="APP_ENDPOINTS.home_health" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Home Health</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Admin -->
                                    <nz-list-item *ngIf="hasRole('admin')">
                                        <a [href]="APP_ENDPOINTS.admin" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Admin</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                    <!-- Super Admin -->
                                    <nz-list-item *ngIf="hasRole('super_admin')">
                                        <a [href]="APP_ENDPOINTS.super_admin" target="_blank">
                                            <div class="d-flex">
                                                <nz-avatar nzIcon="appstore" ngClass="warning"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">Super Admin</p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                </nz-list>
                            </div>
                        </nz-dropdown-menu>
                    </li>

                    <li>
                        <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                            <nz-avatar style="background-color:#FEB2B2;" nzIcon="user" *ngIf="userProfile.avatar === null; else showProfileImage"></nz-avatar>
                            <ng-template #showProfileImage>
                                <nz-avatar nzIcon="user" [nzSrc]="userProfile.avatar" [nzAlt]="'profile picture'"></nz-avatar>
                            </ng-template>
                        </span>
                        <nz-dropdown-menu #profile="nzDropdownMenu">
                            <ul nz-menu class="p-b-15 p-t-20">
                                <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                                    <div class="d-flex m-r-50">
                                        <nz-avatar nzSize="large" style="background-color:#FEB2B2;" nzIcon="user" *ngIf="userProfile.avatar === null; else showProfileImage"></nz-avatar>
                                        <ng-template #showProfileImage>
                                            <nz-avatar nzSize="large" nzIcon="user" [nzSrc]="userProfile.avatar"></nz-avatar>
                                        </ng-template>
                                        <div class="m-l-10">
                                            <p class="m-b-0 text-dark font-weight-semibold">{{ userProfile?.full_name }}</p>
                                            <p class="m-b-0 opacity-07">{{ userProfile?.role }}</p>
                                        </div>
                                    </div>
                                </li>
                                <li nz-menu-item>
                                    <a [routerLink]="['/account/profile']" class="p-v-5 d-flex align-items-center justify-content-between">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                            <span class="m-l-10">Edit Profile</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                                <li *ngIf="this.globals.bulkMessagePermission" nz-menu-item>
                                    <a [routerLink]="['/bulk-message']" class="p-v-5 d-flex align-items-center justify-content-between">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="send" theme="outline"></i>
                                            <span class="m-l-10">Send Message</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                                <li nz-menu-item>
                                    <a [routerLink]="['/account/setting']" class="p-v-5 d-flex align-items-center justify-content-between">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="setting" theme="outline"></i>
                                            <span class="m-l-10">Settings</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                                <li nz-menu-item>
                                    <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                                        <div>
                                            <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                            <span class="m-l-10">Logout</span>
                                        </div>
                                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                                    </a>
                                </li>
                            </ul>
                        </nz-dropdown-menu >
                    </li>
                    <!-- <li>
                        <a (click)="quickViewToggle()">
                            <i nz-icon nzType="appstore" theme="outline"></i>
                        </a>
                        <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                            <app-quick-view></app-quick-view>
                        </nz-drawer>
                    </li> -->
                </ul>
        </div>
    </ng-template>
</div>

<nz-modal [(nzVisible)]="loggingOut" nzClosable="false" nzFooter="''">
    <ng-template nzModalContent>
        <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
        <nz-spin nzSimple [nzIndicator]="indicatorTemplate" class="mt-4 text-center"></nz-spin><br/>
        <div class="text-center">Logging you out.</div>
    </ng-template>
</nz-modal>