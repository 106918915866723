export const environment = {
  production: true,
  apiEndpoint: 'https://testbackend.tupelolife.health',
  appEndpoints: {
    patient: 'https://testapp.tupelolife.health',
    provider: 'https://testprovider.tupelolife.health',
    careteam: 'https://testcareteam.tupelolife.health',
    home_health: 'https://testhomehealth.tupelolife.health',
    admin: 'https://testadmin.tupelolife.health',
    super_admin: 'https://testsuperadmin.tupelolife.health',
    super_super_admin: 'https://testssadmin.tupelolife.health'
  },
  pubnub: {
    publishKey: 'pub-c-02c53bba-3a3a-466c-b455-4b475289b96f',
    subscribeKey: 'sub-c-b573b486-a5bd-11eb-b1ae-be4e92e38e16',
  },
};
