import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { BloodPressureStagePipe } from './pipes/blood-pressure-stage.pipe';
import { BloodGlucoseStagePipe } from './pipes/blood-glucose-stage.pipe';
import { TotalCholesterolStagePipe } from './pipes/total-cholesterol-stage.pipe';
import { HdlCholesterolStagePipe } from './pipes/hdl-cholesterol-stage.pipe';
import { LdlCholesterolStagePipe } from './pipes/ldl-cholesterol-stage.pipe';
import { TriglyceridesStagePipe } from './pipes/triglycerides-stage.pipe';
import { WeightStagePipe } from './pipes/weight-stage.pipe';
import { MinuteSecondPipe } from './pipes/minute-second.pipe';
import { MinutePipe } from './pipes/minute.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SafehtmlPipe } from './pipes/safehtml.pipe';
import { TaskStagePipe } from './pipes/task-stage.pipe';
import { DynamicFilterPipe } from './pipes/dynamic-filter.pipe';
import { OxygenSaturationStagePipe } from './pipes/oxygen-saturation-stage.pipe';
import { TemperatureStagePipe } from './pipes/temperature-stage.pipe';
import { PreferredLanguagePipe } from './pipes/preferred-language.pipe';
import { CsvkeytovaluePipe } from './pipes/csvkeytovalue.pipe';
import { MedicaidStatusPipe } from './pipes/medicaid-status.pipe';
import { ParseCustomAlertPipe } from './pipes/parse-custom-alert.pipe';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzIconModule,
        PerfectScrollbarModule,
        BloodPressureStagePipe,
        BloodGlucoseStagePipe,
        TotalCholesterolStagePipe,
        HdlCholesterolStagePipe,
        LdlCholesterolStagePipe,
        TriglyceridesStagePipe,
        OxygenSaturationStagePipe,
        WeightStagePipe,
        MinuteSecondPipe,
        MinutePipe,
        PhonePipe,
        TaskStagePipe,
        SafehtmlPipe,
        DynamicFilterPipe,
        TemperatureStagePipe,
        PreferredLanguagePipe,
        CsvkeytovaluePipe,
        MedicaidStatusPipe,
        ParseCustomAlertPipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        NzIconModule,
        NzToolTipModule,
        PerfectScrollbarModule
    ],
    declarations: [
        BloodPressureStagePipe,
        BloodGlucoseStagePipe,
        TotalCholesterolStagePipe,
        HdlCholesterolStagePipe,
        LdlCholesterolStagePipe,
        TriglyceridesStagePipe,
        WeightStagePipe,
        MinuteSecondPipe,
        MinutePipe,
        PhonePipe,
        TaskStagePipe,
        SafehtmlPipe,
        DynamicFilterPipe,
        OxygenSaturationStagePipe,
        TemperatureStagePipe,
        PreferredLanguagePipe,
        CsvkeytovaluePipe,
        MedicaidStatusPipe,
        ParseCustomAlertPipe,
    ],
    providers: [
        ThemeConstantService,
        WeightStagePipe,
        CsvkeytovaluePipe,
        MedicaidStatusPipe
    ]
})

export class SharedModule { }
