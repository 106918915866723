import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityHelperService {

  constructor(
    private datePipe: DatePipe
  ) { }

  // default value
  dailyGoals = {
    steps: 3000,
    calories: 1800,
    distance: 3000,
    sleep: 360
  };

  convertSleepDuration(min): string {
    const h = Math.floor(min / 60);
    const m = min % 60;
    return h + 'h ' + m + 'm';
  }

  // custom date formatting to handle iOS issue in datepipe for non Object datetime
  formatDate(d, f): string {
    const date = new Date(d.replace(/-/g, '/'));
    return this.datePipe.transform(date, f);
  }

  // format date to string YYYY-mm-dd e.g. 2022-10-11 ignoring timezone convertion
  formatDateToString(date: Date): string {
    const month = date.getMonth() + 1;
    const sMonth = month.toString().length === 1 ? '0' + month : month;
    const day =  date.getDate();
    const sDay = day.toString().length === 1 ? '0' + day : day;
    return date.getFullYear() + '-' + sMonth  + '-' + sDay;
  }

  formatDateYMD(date) {
    if (date) {
      const d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
      return [year, month, day].join('-');
    }
    return null;
  }

  convertMetersToMiles(m: number): number {
    return this.roundNumber(m * 0.000621371, 2);
  }

  roundNumber(num: number, decimals: number = null): number {
    return null === decimals ? Math.round(num) : parseFloat(num.toFixed(decimals));
  }

  diffInMinutes(dt2, dt1): number {
    const diff = ((dt2.getTime() - dt1.getTime()) / 1000) / 60;
    return Math.abs(Math.round(diff));
  } 

  calculateProgress(v, t): number {
    return v > 0 ? this.roundNumber((v / t) * 100) : 0;
  }

  getMax(arr: any, d: number): number {
    return arr.length > 0 ? arr.reduce((a, b) => Math.max(a, b)) : d;
  }

  ageCalculator(birthday): number{
      const convertAge = new Date(birthday);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      return Math.floor((timeDiff / (1000 * 3600 * 24))/365);    
  }

  formatDateForPicker(value: string) : Date {
    if (value && undefined !== value) {
      const offsetMinutes = new Date().getTimezoneOffset();
      let tzOffset = ' GMT+0';
      if (offsetMinutes > 0) {
        const offsetHours = (offsetMinutes/60 + 1 + ':00').replace('-','');
        tzOffset = ' GMT-' + offsetHours;
      } else if (offsetMinutes < 0) {
        const offsetHours = (offsetMinutes/60 + ':00').replace('-','');
        tzOffset = ' GMT+' + offsetHours;
      }
      return new Date(value.replace(/-/g, '/') + tzOffset);
    }
    return null;
  }

  convertHeightToFeet(cent) {
    const feet = Math.floor(((cent*0.393700) / 12));
    const inches = this.roundNumber((((cent*0.393700) / 12) - feet) * 12,2);
    return {'feet':feet, 'inches': inches};
  }
  convertHeightToCent(feet, inches) {
    return this.roundNumber((feet * 12 + inches) * 2.54,2);
  }

  ellipsis(str: string, num: number = str?.length, ellipsisStr = "...") {
    if (str?.length == 0) {
      return null;
    }
    return str?.length > num
    ? str?.slice(0, num > ellipsisStr?.length ? num - ellipsisStr?.length : num) +
      ellipsisStr
    : str;
  }

  formatManualdate(str: string) {
    if (str) {
      const ar = str.split('-');
      const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      if (ar.length == 3) {
        const yr = ar[0];
        const month = months[Number(ar[1])-1];
        const day = ar[2];
        return month + ' ' + day + ', ' + yr;
      }
    }
    return null;
  }
}
